<template>
  <div>
    <b-row>
      <b-col sm="12" lg="3">
        Tanı
      </b-col>
      <b-col sm="12" lg="7">
        <v-select v-model="p_diagnosisData.selected" :options="p_patientData.diagnosis_list"></v-select>
      </b-col>
      <b-col sm="12" lg="2">
        <b-button variant="secondary" size="md" @click="f_openNewDiagnosisModal()" title="Yeni Tanı Eklemek İçin Tıklayınız."> <i class="fa fa-plus"></i> yeni </b-button>
      </b-col>
    </b-row>
    <modal v-if="d_showDiagnosisModal" @close="d_showDiagnosisModal = false" :width="'500'">
      <h3 slot="header">Tanı Ekleme Ekranı</h3>
      <div slot="body">
        <b-row>
          <b-col cols="4">
            <strong> Tanı seçimi </strong>
          </b-col>
          <b-col cols="8">
            <template v-if="d_favoriteDiagnosis">
              <v-select v-model="d_newDiagnosis.selected" :options="d_favoriteDiagnosisList"></v-select>
            </template>
            <template v-else>
              <v-select v-model="d_newDiagnosis.selected" :options="d_allDiagnosisList"></v-select>
            </template>
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_saveDiagnosis()">Kaydet</button>
        <button type="button" class="btn btn-danger" @click="d_showDiagnosisModal = false">Kapat</button>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  default as Modal
} from '@/components/widgets/Modal';
import vSelect from 'vue-select';
import { mapGetters } from 'vuex';
export default {
  name: 'DiagnosisSelection',
  computed: mapGetters({
    // lang: 'lang'
  }),
  components: {
    vSelect,
    Modal
  },
  props: {
    p_diagnosisData: {
      type: Object,
      required: true
    },
    p_patientData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      d_allDiagnosisList: [],
      d_favoriteDiagnosisList: require('@/options/favorite_diagnosis_c_codes').options,
      d_favoriteDiagnosis: true,
      d_newDiagnosis: { 'selected': '' },
      d_showDiagnosisModal: false
    };
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_saveDiagnosis: function () {
      this.p_patientData.diagnosis_list.push(this.d_newDiagnosis.selected);
      this.p_diagnosisData.selected = this.d_newDiagnosis.selected;
      this.d_showDiagnosisModal = false;
    },
    f_openNewDiagnosisModal: function () {
      this.d_showDiagnosisModal = true;
    }
  },
  watch: {}
}

</script>

<style type="text/css">


</style>

